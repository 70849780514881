<template>
	<desModal :titleRight="showDate" widthNum="35%" :footerNone="false" :delShow="false" :showClose="true" title="选择日期" class="calendar-modal" @clickDelAction="clickDel">
		<div class="calendar-b">
			<el-row class="week-box">
				<span v-for="(item, index) in weeks" :key="index" class="i-block w14">{{ item }}</span>
			</el-row>
			<el-row type="flex" justify="space-between" class="date-box" v-if="type == 2">
				<div :class="'w50' + (dateActive == 1 ? ' calendar-active' : '')" @click="changeDate(1)">去程日期</div>
				<div :class="'w50' + (dateActive == 2 ? ' calendar-active' : '')" @click="changeDate(2)">回程日期</div>
			</el-row>
			<div class="h400">
				<div v-for="(list, index) in calendars" :key="index">
					<div class="year-box">
						<span className="ft12 gray9">{{ list.year }}年</span>
						<span>{{ (list.month > 9 ? list.month : '0' + list.month) + '月' }}</span>
					</div>
					<el-row>
						<el-row>
							<div v-for="(culateEmptyGrids, index) in list.culateEmptyGrids" :key="index" class="i-block w14"></div>
							<div v-for="(days, index) in list.days" :key="index" class="i-block w14" @click="changeTime(days.date, type, nowDate)">
								<div class="calender-diasabled" v-if="days.date < nowDate">{{ index + 1 }}</div>
								<div
									v-if="days.date >= nowDate"
									:class="'i-block calender' + (days.date <= timevalue2 && changeIndex > 0 ? ' gary9' : ' black')"
									:disabled="days.date <= timevalue2 && changeIndex > 0 ? 'disabled' : ''"
								>
									{{ index + 1 }}
								</div>
							</div>
						</el-row>
					</el-row>
				</div>
			</div>
		</div>
	</desModal>
</template>

<script>
import desModal from '../desModal.vue';
import moment from 'moment';

var util = require('@/utils/util.js');

var that;

export default {
	name: 'calendar',
	components: {
		desModal
	},
	props: ['showDate', 'type', 'timevalue2', 'changeIndex', 'today'],
	data() {
		return {
			dateActive: 1,
			weeks: ['日', '一', '二', '三', '四', '五', '六'],
			nowDate: moment().format('YYYY-MM-DD'),
			
			monthList:"",
			curDate: '',
			maxDate: '',
			minDate: '',
		};
	},
	//创建
	created() {
		this.getMonths();
	},

	//方法
	methods: {
		changeDate(index) {
			console.log(index);
			this.dateActive = index;
		},
		changeTime(time, type, today) {
			this.$emit('changeTime', time, type, today);
			console.log(time, type, today);
		},
		clickDel() {
			this.$emit('changeTimeDel');
		},
		getMonths() {
			var date = new Date();
			var cur_year = date.getFullYear();
			var cur_month = date.getMonth() + 1;
			var curDate = moment(new Date(cur_year, cur_month - 1, date.getDate())).format('YYYY-MM-DD');
			let arrays = [],
				nmonth,
				nyear;
			for (var i = 0; i < 13; i++) {
				let inum = cur_month + i;
				nmonth = (cur_month + i) % 12;
				if (nmonth == 0) {
					nmonth = 12;
				}
				if (inum > 12) {
					nyear = cur_year + 1;
				} else {
					nyear = cur_year;
				}

				var days = this.getDays(nyear, nmonth);
				var culateEmptyGrids = this.getculateEmptyGrids(nyear, nmonth);

				arrays.push({
					year: nyear,
					month: nmonth,
					days: days,
					culateEmptyGrids: culateEmptyGrids
				});
			}

			this.calendars = arrays;
			this.Datacalendar = arrays;
			console.log(1 + this.calendars[0].year);
		},
		getDays(year, month) {
			let nowDays = new Date(year, month, 0).getDate(),
				days = [];

			for (let i = 0; i < nowDays; i++) {
				var newDate = new Date(year, month - 1, i + 1),
					date = moment(newDate).format('YYYY-MM-DD'),
					holiday = '';
				var holiday = this.isHolidays(year, month, i + 1);
				days.push({
					date: date,
					holiday: holiday
				});
			}
			return days;
		},
		getculateEmptyGrids(year, month) {
			let firstDayofWeek = new Date(Date.UTC(year, month - 1, 1)).getDay(),
				emptyGrids = [];
			if (firstDayofWeek > 0) {
				for (let i = 0; i < firstDayofWeek; i++) {
					emptyGrids.push(i);
				}
			}
			return emptyGrids;
		},

		isHolidays(year, month, date) {
			let holiday = '',
				mdate = new Date(year, month - 1, date).toString('yyyyMMdd');
			mdate = new RegExp(mdate);
			if (month == 1 && date == 1) {
				holiday = '元旦';
			}
			if (month == 5 && date == 1) {
				holiday = '劳动节';
			}
			if (month == 9 && date == 10) {
				holiday = '教师节';
			}
			if (month == 10 && date == 1) {
				holiday = '国庆';
			}
			if (month == 12 && date == 25) {
				holiday = '圣诞节';
			}
			let holidays = {
				chixi: ['20180215', '20190204', '20200124', '20210211'],
				chunjie: ['20180216', '20190205', '20200125', '20210212'],
				yuanxiao: ['20180302', '20190219', '20200208', '20210226'],
				duanwu: ['20170530', '20180618', '20190607', '20200625'],
				qingming: ['20180405', '20190405', '20200404'],
				qixi: ['20170828', '20190807', '20200825'],
				zhongqiu: ['20171004', '20180924', '20190913']
			};
			if (mdate.test(holidays.chixi.join('-'))) {
				holiday = '除夕';
			}
			if (mdate.test(holidays.chunjie.join('-'))) {
				holiday = '春节';
			}
			if (mdate.test(holidays.yuanxiao.join('-'))) {
				holiday = '元宵';
			}
			if (mdate.test(holidays.qingming.join('-'))) {
				holiday = '清明';
			}
			if (mdate.test(holidays.duanwu.join('-'))) {
				holiday = '端午';
			}
			if (mdate.test(holidays.qixi.join('-'))) {
				holiday = '七夕';
			}
			if (mdate.test(holidays.zhongqiu.join('-'))) {
				holiday = '中秋';
			}
			return holiday;
		}
	}
};

function getCalenderList() {
	// util.showLoding();
	var parm = {
		maxDate: that.maxDate,
		minDate: that.minDate,
		product: that.product
	};
	util.getAjaxRequest('/MiniAppUtil/GetCalender', parm, function(result) {
		util.consoleLog(result);
		if (result.code == 0) {
			that.monthList = result.data.calenderList;
			// setToView();
			// setTimeout(function() {
			// 	setToView();
			// }, 1000);
		}
		uni.hideLoading();
	});
}
</script>

<style lang="scss">
@import './calendar.scss';
</style>
