<template>
	<div style="position: absolute;z-index: 999;">
		<div class="fast-box">
			<div class="hd"> <span class="fr close" data-type="close" @click="onhideCity">×</span> <span class="header">可直接输入中文/代码/拼音/英文</span>
			</div>
			<div class="bd clearfix">
				<div class="side-nav-list fl">
					<ul>
						<li class="select">热门</li>
						<li class="">热门</li>
					</ul>
				</div>
				<div class="content fl">
					<div class="nano has-scrollbar">
						<div class="nano-content" style="right: -17px;">
							<div class="title" data-type="itemsTitle">热门</div>
							<div class="con" data-type="itemsPanel">
								<span @click="onhideCity">阿维尼翁</span>
							</div>
						</div>
						<div class="nano-pane" style="display: block;">
							<div class="nano-slider" style="height: 323px; transform: translate(0px, 0px);"></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="filter-box" style="width: 258px;">
			<div class="af-no-results"  style="display: none;">对不起, 找不到: 肯尼亚(KE)看</div>
			<div class="af-results" style="display: block;">
				<div class="af-title"> <span class="fr close" data-type="close">×</span> <span class="af-title-content" data-type="title">←↑↓→
						选择</span> </div>
				<div data-type="itemsPanel">
					<ul class="af-items">
						<li data-type="item"  class="selected">
							<p>肯尼亚 (KE)</p>
						</li>
						<li data-type="item">
							<p>吉尔吉斯斯坦 (KG)</p>
						</li>
						
					</ul>
					<ul class="af-pager">
						<li class="current">1</li>
						<li>2</li>
						<li>3</li>
						<li>4</li>
						<li>5</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'InsuranceCity',
		props: ['showCity'],
		methods: {
			onhideCity() {
				this.$emit('onhideCity');
				console.log(1);
			}

		}
	}
</script>

<style>
</style>
