<template>
	<div class="Insurance">
		<div class="container-wrap">
			<div class="insurance-wrap">
				<div class="insurance-im">
					<!-- 顶部背景图片 -->
					<img class="insurance-ima" src="../../assets/baoxian01.png">
					<div class="insurance-imb">
						<div class="insurance-shijian">
							<div class="insurance-shijian-ba">
								<div class="insurance">
									<span class="iconfont icon-baoxian4 insurance-baox"></span>
									<div class="insurance-ins">保险</div>
								</div>
								<div class="insurance-shijian-a">
									<div class="insurance-shijian-mudidi">
										<p>目的地</p>
										<div class="insurance-inputicon">
											<!-- <el-input class="insurance-icon-d" placeholder="中文/拼音/英文" v-model="InsurSearchInfo.DestinationCountry" @focus="onshowCity()"></el-input> -->
											<el-autocomplete style="width:100%" class="inline-input"
												v-model="DestinationCountryVal" :fetch-suggestions="queryCitySearch"
												placeholder="英文/中文/三字码" :trigger-on-focus="false"
												@select="handleSelectCity"></el-autocomplete>

											<!-- <el-input class="insurance-icon-d" placeholder="中文/拼音/英文" v-model="InsurSearchInfo.DestinationCountry" ></el-input> -->
											<span class="insurance-icon iconfont icon-didian"></span>
											<div class="mdd" v-if="showCity==true">
												<InsuranceCity @onhideCity="onhideCity" />
											</div>
										</div>
									</div>
									<div class="insurance-shijian-b">
										<p>保障日期</p>
										<div class="insurance-inputicon in-border" style="text-align:left;">

											<el-date-picker value-format="yyyy-MM-dd" v-model="dates" size="medium"
												class="" style="width: 295px;" type="daterange" range-separator="—"
												start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false"
												:picker-options="pickerOptions0" @change="changedate"></el-date-picker>
											<span class="iconfont el-icon-date icon-yqfrili-a"></span>
											<!-- <el-date-picker
												class="insurance-icon-a"
												v-model="InsurSearchInfo.StartDate"
												:clearable="false"
												type="date"
												placeholder="YYYY-MM-DD"
											></el-date-picker> -->
											<!-- <span class="insurance-icon iconfont icon-rili"></span> -->
											<!-- 如果图标不行可以换跟他原来一样的 -->
										</div>
									</div>

									<!-- <div class="insurance-icon-b insurance-shijian-b">
										<p>结束时间</p>
										<div class="insurance-inputicon">
											<el-date-picker class="insurance-icon-b" v-model="InsurSearchInfo.EndDate" :clearable="false" type="date" placeholder="YYYY-MM-DD"></el-date-picker>								
										</div>
									</div> -->

									<div class="insurance-shijian-b">
										<p>保险公司</p>
										<div class="insurance-inputicon">
											<el-select v-model="InsurSearchInfo.GoodOwnerCode" placeholder="请选择"
												class="insurance-icon-cc">
												<el-option value="请选择"></el-option>
												<el-option class="insurance-icon-c" v-for="item in InsurOwners"
													:key="item.GoodOwnerCode" :label="item.OwnerNameCn"
													:value="item.GoodOwnerCode"></el-option>
											</el-select>
											<!-- <span class="insurance-icon iconfont icon-xiangxia2" style="font-size: 16px;"></span> -->
										</div>
									</div>
									<div class="insurance-shijian-suo">
										<a class="insurance-shijian-c" href="javascript:;" @click="bindSearch">搜索</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="insurance-scene">
					<div class="insurance-i">
						<p>保险场景分类</p>
						<span class="insurance-in"></span>
					</div>

					<el-row :gutter="0" type="flex" class="insurance-img" justify="space-between">
						<a href="javascript:;" @click="bindSearchByLabel('航空意外险')">
							<div class="insurance-img-oneb">
								<!-- 保险场景新修改 -->
								<div class="insurance-img-one">
									<img src="../../assets/baoxian02.png" class="insurance-img-baox">
								</div>
								<div class="insurance-img-onea">
									<div class="insurance-p">
										<p>航空意外险</p>
									</div>
									<div class="insurance-a">
										查看更多
										<div class="iconfont el-icon-arrow-right icon-jiantou-a"></div>
									</div>
								</div>
							</div>
						</a>
						<a href="javascript:;" @click="bindSearchByLabel('境外旅游险')">
							<div class="insurance-img-oneb">
								<!-- 保险场景新修改 -->
								<div class="insurance-img-two">
									<img src="../../assets/baoxian03.png" class="insurance-img-baox">
								</div>
								<div class="insurance-img-onea">
									<div class="insurance-p">
										<p>境外旅游险</p>
									</div>
									<div class="insurance-a">
										查看更多
										<div class="iconfont el-icon-arrow-right icon-jiantou-a"></div>
									</div>
								</div>
							</div>
						</a>
						<a href="javascript:;" @click="bindSearchByLabel('出国签证')">
							<div class="insurance-img-oneb">
								<!-- 保险场景新修改 -->
								<div class="insurance-img-three">
									<img src="../../assets/baoxian04.png" class="insurance-img-baox">
								</div>
								<div class="insurance-img-onea">
									<div class="insurance-p">
										<p>出国签证</p>
									</div>
									<div class="insurance-a">
										查看更多
										<div class="iconfont el-icon-arrow-right icon-jiantou-a"></div>
									</div>
								</div>
							</div>
						</a>
						<a href="javascript:;" @click="bindSearchByLabel('邮轮保险')">
							<div class="insurance-img-oneb">
								<!-- 保险场景新修改 -->
								<div class="insurance-img-four">
									<img src="../../assets/baoxian05.png" class="insurance-img-baox">
								</div>
								<div class="insurance-img-onea">
									<div class="insurance-p">
										<p>邮轮保险</p>
									</div>
									<div class="insurance-a">
										查看更多
										<div class="iconfont el-icon-arrow-right icon-jiantou-a"></div>
									</div>
								</div>
							</div>
						</a>
					</el-row>
				</div>
				<div class="" style="background: #FFFFFF;">
					<div class="insurance-product" v-if="HotInsList.length>0">
						<div class="insurance-i">
							<p>热门产品</p>
							<span class="insurance-in"></span>
						</div>

						<!-- <div class="img-warp">
							<div class="img-ins">
								<div class="img-insul">
									<div class="img-imgoun">
										<div class="img-imgouna">
											<a href="javascript:;" @click="bindJumpDetail(item)">
												<div class="img-text-c">
													<p>全球探索</p>
												</div>
												<div class="img-text">
													<div class="img-text-a">
														<p></p>
													</div>
													<div class="img-text-b">
														<div class="img-text-d">
															<p>¥</p>
															<p>97</p>
															<p>起</p>
														</div>
													</div>
												</div>
												<img class="img-imgounb" src="../../assets/baoxian06.png" alt="" />
											</a>
										</div>
										<div class="img-imgouna">
											<a href="javascript:;" @click="bindJumpDetail(item)">
												<div class="img-text-c">
													<p>白银计划</p>
												</div>
												<div class="img-text">
													<div class="img-text-a">
														<p></p>
													</div>
													<div class="img-text-b">
														<div class="img-text-d">
															<p>¥</p>
															<p>80</p>
															<p>起</p>
														</div>
													</div>
												</div>
												<img class="img-imgounb" src="../../assets/baoxian07.png" alt="" />
											</a>
										</div>
										<div class="img-imgouna">
											<a href="javascript:;" @click="bindJumpDetail(item)">
												<div class="img-text-c">
													<p>邮轮计划一</p>
												</div>
												<div class="img-text">
													<div class="img-text-a">
														<p></p>
													</div>
													<div class="img-text-b">
														<div class="img-text-d">
															<p>¥</p>
															<p>85</p>
															<p>起</p>
														</div>
													</div>
												</div>
												<img class="img-imgounb" src="../../assets/baoxian08.png" alt="" />
											</a>
										</div>
										<div class="img-imgouna">
											<a href="javascript:;" @click="bindJumpDetail(item)">
												<div class="img-text-c">
													<p>港澳计划</p>
												</div>
												<div class="img-text">
													<div class="img-text-a">
														<p></p>
													</div>
													<div class="img-text-b">
														<div class="img-text-d">
															<p>¥</p>
															<p>20</p>
															<p>起</p>
														</div>
													</div>
												</div>
												<img class="img-imgounb" src="../../assets/baoxian09.png" alt="" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div> -->
						<div class="img-warp">
							<div class="img-ins">
								<div class="img-insul">
									<div class="img-imgoun">
										<div class="img-imgouna" v-for="(item,index) in HotInsList" :key="index">
											<a href="javascript:;" @click="bindJumpDetail(item)">
												<div class="img-text-c">
													<p>{{item.PlanName}}</p>
												</div>
												<div class="img-text">
													<!-- <div class="img-text-a">
														<p>{{item.ProductName}}</p>
													</div> -->
													<div class="img-text-b">
														<div class="img-text-d">
															<p>¥</p>
															<p>{{item.MinPremium}}</p>
															<p>起</p>
														</div>
													</div>
												</div>
												<img class="img-imgounb" :src="src[index]" alt="" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						



						<!-- <div class="img-warp"> -->
						<!-- <div class="img-ins"> -->
						<!--箭头 <div class="iconleft" @click="zuohua"><span class="iconfont icon-fanhui1"></span></div> -->
						<!-- <div class="img-insul">
									<ul :style="{ left: calleft + 'px' }">
										<li v-for="(item,index) in HotInsList" :key="index">
											<a href="javascript:;" @click="bindJumpDetail(item)">
												<div class="img-text-c"><p>{{item.PlanName}}</p></div>
												<div class="img-text">
													<div class="img-text-a"><p>{{item.ProductName}}</p></div>
													<div class="img-text-b">
														<div class="img-text-d">
															<p>¥</p>
															<p>{{item.MinPremium}}</p>
															<p>起</p>
														</div>
													</div>
												</div>
												<img src="../../assets/index_bg1.jpg" alt="" />
											</a>
										</li>
									</ul>
								</div> -->
						<!--箭头 <div class="iconright" @click="youhua"><span class="iconfont icon-jiantouyoubian"></span></div> -->
						<!-- </div> -->
						<!-- </div> -->
					</div>
				</div>

				<!-- <calendar :showDate="showDate" @changeTime="changeTime" :type="type" :timevalue2="time2" :changeIndex="id"
				 @changeTimeDel="changeTimeDel"></calendar> -->
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getInsurSearchInfo,
		setInsurSearchInfo
	} from '@/utils/localdatas';
	import InsuranceCity from '@/components/InsuranceCity/InsuranceCity.vue';
	import calendar from '../../components/calendar/calendar.vue';
	var util = require('@/utils/util.js');
	var destApi = require('@/api/destApi.js');
	var insApi = require('@/api/insApi.js'); //保险接口
	var datehelper = require('@/utils/datehelper.js');
	var that;

	export default {
		components: {
			InsuranceCity,
			// calendar
		},
		data() {
			return {
				src: [
					'https://feihuo.yiqifei.com/Content/images/baoxian/baoxian06.png',
					'https://feihuo.yiqifei.com/Content/images/baoxian/baoxian07.png',
					'https://feihuo.yiqifei.com/Content/images/baoxian/baoxian08.png',
					'https://feihuo.yiqifei.com/Content/images/baoxian/baoxian09.png'
				],
				calleft: 0,
				InsurSearchInfo: '',
				HotInsList: [],
				dates: [],
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				options: [],
				InsurOwners: [],
				showCity: '',
				DestinationCountryVal: ''
			};
		},

		//创建
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			this.$router.push({
				path: '/'
			});
			getInsurSearchInfoJSON(null);
			getHotInsList();
			getInsurOwners();
		},
		//方法
		methods: {
			//搜索国家
			queryCitySearch(queryString, cb) {
				var parm = {
					keyword: queryString,
					isCountry: true
				};
				destApi.GetCityList(parm, function(result) {
					// console.log(result);;
					if (result.code == 0) {
						var allCityList = result.data.allCityList;
						cb(allCityList);
						// console.log(that.restaurants);
					} else {
						cb([]);
					}
				});

				// cb(results);
			},
			handleSelectCity(item) {
				console.log(item);
				that.DestinationCountryVal = item.value;
				that.InsurSearchInfo.DestinationCountry = item.code;
			},
			changedate(e) {
				that.InsurSearchInfo.StartDate = that.dates[0];
				that.InsurSearchInfo.EndDate = that.dates[1];
				// that.InsurSearchInfo.StartDate=moment(e).format('YYYY-MM-DD');
				// console.log(that.InsurSearchInfo.StartDate); //打印出开始日期
				var day = datehelper.getDaysBetween(that.InsurSearchInfo.StartDate, that.InsurSearchInfo.EndDate);
				that.InsurSearchInfo.Days = day; //将计算出来的day天数赋值给后台接口days天数
				console.log(day);
			},
			onshowCity() {
				console.log(1);
				this.showCity = true
			},
			onhideCity() {
				this.showCity = ''
			},
			bindReturn(e) {
				this.$router.push({
					path: '/Insurance/list'
				});
			},
			confirm(e) {
				this.$router.push({
					path: '/Insurance/detail'
				});
			},
			// zuohua() {
			// 	this.calleft -= 370;
			// 	if (this.calleft < -370) {
			// 		this.calleft = 0;
			// 	}
			// },
			//点击按钮右移
			// youhua() {
			// 	this.calleft += 370;
			// 	if (this.calleft > 0) {
			// 		this.calleft = -370;
			// 	}
			// },
			bindJumpDetail(item) {
				that.$router.push({
					path: '/Insurance/detail',
					query: {
						ProductCode: item.ProductCode,
						PlanCode: item.PlanCode
					}
				});
			},
			bindSearchByLabel(val) {
				that.InsurSearchInfo.StartDate = that.dates[0];
				that.InsurSearchInfo.EndDate = that.dates[1];
				that.InsurSearchInfo.PlanLabel = val;
				// return;
				var parm = {
					InsurSearchInfoJSON: JSON.stringify(that.InsurSearchInfo)
				};
				getInsurSearchInfoJSON(parm);
			},
			bindSearch(e) {
				console.log(that.InsurSearchInfo.StartDate);
				that.InsurSearchInfo.StartDate = that.dates[0];
				that.InsurSearchInfo.EndDate = that.dates[1];

				// return;
				var parm = {
					InsurSearchInfoJSON: JSON.stringify(that.InsurSearchInfo)
				};
				getInsurSearchInfoJSON(parm);
			}
		}
	};

	//获取查询的信息
	function getInsurSearchInfoJSON(parm) {
		insApi.GetInsurSearch(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				let InsurSearchInfo = result.data.InsurSearchInfo;
				that.dates = [InsurSearchInfo.StartDate, InsurSearchInfo.EndDate];
				setInsurSearchInfo(InsurSearchInfo);
				// return;
				if (parm != null) {
					that.$router.push({
						path: '/Insurance/list'
					});
				} else {
					that.InsurSearchInfo = result.data.InsurSearchInfo;
				}
				//获得查询信息之后将查询的信息传入保险列表的接口去查询列表信息
				// getInsuranceRateList();
			} else {
				that.$message(result.msg);
			}
		});
	}
	//获取查询的信息
	function getHotInsList(parm) {
		insApi.GetHotInsList(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.HotInsList = result.data.HotInsList;
			} else {}
		});
	}

	function getInsurOwners() {
		insApi.GetInsurOwners(null, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.InsurOwners = result.data.InsurOwners;
			} else {}
		});
	}
</script>

<style lang="scss">
	@import '../../style/Insurance/Insurance.scss';
</style>
